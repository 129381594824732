import React from 'react'
import Layout from '../../components/layout'
import Map from '../../components/Map/Map'
import LiveTime from '../../components/Time'
import { Helmet } from 'react-helmet'
import Distributors from '../../components/Distributors/Distributors'

const Контакты = () => {
  return (
    <Layout>
      <Helmet>
        <title>Контакты IN-ECO</title>
      </Helmet>
      <div className="header-contacts">
        <div className="columns">
          <div className="column contact-info-column">
            <h2 className="title title-bordered">Давайте сотрудничать!</h2>
            <h3 className="title title-small">
              Наш офис и склад удобно расположены в Каунасе. Свяжитесь с нами
              любыми способами!
            </h3>
            <div className="columns contact-info">
              <div className="column">
                <h2 className="title title-middle title-bordered">КОНТАКТЫ</h2>
                <div className="content-text content-row">
                  <span>Email </span>
                  <span>info@in-eco.biz</span>
                </div>
                <div className="content-text content-row">
                  <span className="content-title">Tel.</span>+370 37 21 42 25
                </div>
                <div className="content-text content-row">
                  <span className="content-title">Fax</span>+370 37 21 42 26
                </div>
                <div className="content-text content-text-offset">
                  Ašigalio g. 6 II korpusas <br /> Каунас, LT-49142 <br /> Литва
                </div>
              </div>
              <div className="column">
                <h2 className="title title-middle title-bordered">
                  РАБОЧЕЕ ВРЕМЯ
                </h2>
                <div className="content-text content-row">
                  <span className="content-title">I-IV</span>8:00 - 17:00
                </div>
                <div className="content-text content-row">
                  <span className="content-title">V</span>8:00 - 16:00
                </div>
                <div className="content-text content-row">
                  <span className="content-title">Перерыв</span>12:00 - 13:00
                </div>
                <h3 className="content-text content-text-offset text-accented">
                  <span className="content-title">Местное время:</span>
                  <LiveTime />
                </h3>
              </div>
            </div>
          </div>
          <Distributors
            title="Наши дистрибьюторы"
            czechRepublic="Чехия"
            latvia="Латвия"
            poland="Польша"
            ukraine="Украина"
            macedonia="Македония"
            uzbekistan="Узбекистан"
            kazakhstan="Казахстан"
            serbia="Сербия"
          />
        </div>
      </div>
      <Map />
    </Layout>
  )
}

export default Контакты
